<template>
  <div class="">
    <v-btn small color="btnColor" class="mr-1 white--text" @click="uploaddata = []; file = null; dialog = true">
      <v-icon class="mr-1">mdi-upload-multiple</v-icon>
      <span>Bulk Upload</span>
    </v-btn>
    <lb-dailogboxnew v-model="dialog" :width="(uploaddata.length > 0) ? '' : '400'"
      heading="Bulk Information Requirement Upload" :loading="loading">
      <template v-slot:body>
        <div>
          <div class="text-center mb-1">Upload the bulk information requirement template here.</div>
          <div class="d-flex align-center justify-center">
            <lb-file v-model="file" label="" class="py-0 my-0" :loading="fileprocessing['file'] === 1" :drag="false"
              hidedetails @change="readFile('file')" :displayname="false" accept=".xlsx,.xls,.xlsm,.xlsb" />
            <v-btn color="btnColor" small class="white--text ml-2"
              @click="$nova.downloadFile('', {}, '/v2/financialclose/masters/informationmanager/getbulksubmittemplate')">Download
              Template</v-btn>
          </div>
        </div>
        <div v-if="uploaddata.length > 0" class="mt-3">
          <v-simple-table dense class="FC-Table">
            <template v-slot:default>
              <thead>
                <th class="text-left" scope="type">Type</th>
                <th class="text-left" scope="category">Category</th>
                <th class="text-left" scope="description">Description</th>
                <th class="text-left" scope="info_owner_name">Information Owner Name</th>
                <th class="text-left" scope="info_owner_email">Information Owner Email</th>
                <th class="text-left" scope="preparer">Urgent</th>
                <th class="text-left" scope="approver">Tasklist Reference</th>
                <th class="text-left" scope="approver">Location</th>
                <th class="text-left" scope="duedate">Due Days</th>
              </thead>
              <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                <tr v-for="(v, k) in uploaddata" :key="k" class="">
                  <template v-for="(vv, kk) in displaycolumns">
                    <td v-if="dataerror[k][vv]" :key="kk" class="error--text font-weight-bold">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-if="vv === 'checklistref'" class="d-inline-block">
                            <v-chip v-for="(va, ka) in v[vv]" :key="ka" v-bind="attrs" v-on="on" color="error" small outlined
                              class="pl-2 mb-1 mr-1">
                              <v-icon>mdi-tag</v-icon>
                              <span class="pl-1">{{ va }}</span>
                            </v-chip>
                          </div>
                          <div v-else-if="vv === 'urgent'">
                            {{ v[vv] }}
                            <v-chip small v-if="v[vv]" label color="error">Yes</v-chip>
                            <v-chip small v-else label>No</v-chip>
                          </div>
                          <div v-else-if="vv === 'type'">
                            <v-chip v-if="v.type==='text'" small outlined>
                              <v-icon>mdi-text-short</v-icon>
                              <span class="pl-1">Text</span>
                            </v-chip>
                            <v-chip v-if="v.type==='fileupload'" small outlined>
                              <v-icon>mdi-file-upload</v-icon>
                              <span class="pl-1">File Upload</span>
                            </v-chip>
                          </div>
                          <span v-else v-bind="attrs" v-on="on">Error</span>
                        </template>
                        <span>{{ dataerror[k][vv] }}</span>
                      </v-tooltip>
                    </td>
                    <td v-else :key="kk">
                      <div v-if="vv === 'checklistref'">
                        <v-chip v-for="(va, ka) in v[vv]" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                          <v-icon>mdi-tag</v-icon>
                          <span class="pl-1">{{ va }}</span>
                        </v-chip>
                      </div>
                      <div v-else-if="vv === 'type'">
                        <v-chip v-if="v.type==='text'" small outlined>
                          <v-icon>mdi-text-short</v-icon>
                          <span class="pl-1">Text</span>
                        </v-chip>
                        <v-chip v-if="v.type==='fileupload'" small outlined>
                          <v-icon>mdi-file-upload</v-icon>
                          <span class="pl-1">File Upload</span>
                        </v-chip>
                      </div>
                      <div v-else-if="vv === 'urgent'">
                        <v-chip small v-if="v[vv]" label color="error">Yes</v-chip>
                        <v-chip small v-else label>No</v-chip>
                      </div>
                      <div v-else>{{ v[vv] }}</div>
                    </td>
                  </template>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <div v-if="uploaddata.length > 0">
          <v-btn small class="ml-2 white--text" color="btnColor" v-if="!flag" @click="uploadData()">Verify &
            Upload</v-btn>
          <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload
          </div>
        </div>
      </template>
    </lb-dailogboxnew>
  </div>
</template>

<script>

export default {
  name: 'financialclose_component_checklist_bulkupload',
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_checklist",
      // generateddescription: "",
      file: null,
      fileprocessing: {},
      displaycolumns: ['type', 'category', 'description', 'info_owner_name', 'info_owner_email', 'urgent','checklistref','location','duedate'],
      flag: false,
      uploaddata: [],
      dataerror: [],
      dialog: false,
      loading: false,
    }
  },
  created() {
  },
  activated() {
  },
  methods: {
    init() {
    },
    readFile(key) {
      let file = this.file;
      // let file = undefined;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['Tasklist Reference', 'Category', 'Description', 'Info Owner Name', 'Info Owner Email', 'File Upload', 'Urgent',"Location",'Due Days'];
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            // let grouplist = [];
            // for (let i = 0; i < dt.length; i++) {
            //   if(i > 0){
            //     const el = dt[i];
            //     let gname = (el[0] || "").toString();
            //     if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
            //   }
            // }
            let filedata = [];
            // let grouporder = [];
            let errordata = [];
            this.flag = false;
            // let gstartnum = 1;
            // let reflist = [];
            for (let i = 0; i < dt.length; i++) {
              console.log("qwer5tyu",i);
              if (i > 0 && dt[i].length>0) {
                const el = dt[i];
                // let gname = (i).toString();
                // if(gname === ""){
                //   let newgname = "g"+(gstartnum || "").toString();
                //   while(grouplist.indexOf(newgname) !== -1) {
                //     newgname = "g"+(gstartnum || "").toString();
                //     gstartnum = gstartnum+1;
                //   }
                //   gname = newgname;
                //   if(grouplist.indexOf(gname) === -1) grouplist.push(gname);
                // }
                let rowdata = { "checklistref": (el[0] || "").split(",").filter(x => x.replaceAll(/\s/g, '')), "category": el[1], "description": el[2], "info_owner_name": el[3], "info_owner_email": el[4], "file_upload": el[5], "urgent": el[6],"location": el[7], "duedate": el[8]};
                let fileupload = (rowdata.file_upload || "").toString().replaceAll(/\s/g, '').toLowerCase();
                if (fileupload === "y") fileupload = "yes";
                rowdata.type = (fileupload === "yes") ? "fileupload" : "text";
                let urgent = (rowdata.urgent || "").toString().replaceAll(/\s/g, '').toLowerCase();
                if (urgent === "y") urgent = "yes";
                rowdata.urgent = (urgent === "yes") ? true : false;
                if ((rowdata.category || "").toString() === "") rowdata.category = "Others";
                // if(gname !== "" && grouplist.indexOf(gname) === -1) grouplist.push(gname);
                // if(grouporder.indexOf(gname) === -1) grouporder.push(gname)
                // errordata[gname] = errordata[gname] || [];
                let errors = {};
                // let tagtext = (el[0] || "").toString();
                // if(tagtext === "") errors[0] = "Tag is required";
                // else{
                //   if(reflist.indexOf(tagtext) > -1) errors[0] = "Duplicate tag found";
                //   else reflist.push((el[0] || "").toString());
                // }
                // else if(groupdata[gname].length > 0) {
                //   if((el[0] || "").toString() !== groupdata[gname][0].category) errors[0] = "Group items should have same category";
                // }
                if ((rowdata.category || "").toString() === "") errors["description"] = "Description is required";
                if ((rowdata.description || "").toString() === "") errors["description"] = "Description is required";
                if ((rowdata.info_owner_name || "").toString() === "") errors["info_owner_name"] = "Information Owner Name is required";
                if ((rowdata.info_owner_email || "").toString() === "") errors["info_owner_email"] = "Information Owner Email is required";
                if (Object.keys(errors).length > 0) this.flag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataerror = errordata;
            this.dialog = true;
          } else throw Error("Columns '" + headings.join("','") + "' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    uploadData() {
      this.loading = true;
      this.axios.post("/v2/financialclose/masters/informationmanager/bulkadd", { data: this.uploaddata }).then(dt => {
        if (dt.data.status === "success") {
          this.dialog = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "Information Requirement uploaded successfully");
        } else throw dt.data.message || "Error uploading Information Requirement";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
  }
}
</script>
